<template>
    <b-card class="st-section" id="application-details-target">
        <div class="st-section-body">
            <form class="form"
                  novalidate="novalidate"
                  id="st_application_details_form"
                  ref="st_application_details_form"
              >
                <div class="form-group">
                    <label>
                        {{ fields.application_type_id.label }} *
                    </label>
                    <st-select
                        v-model="model[fields.application_type_id.name]"
                        :ref="fields.application_type_id.name"
                        :field="fields.application_type_id"
                        :formValidation="fv"
                        :autoSelectFirstOption="autoSelectFirstOption"
                        searchable
                    />
                </div>
                <p v-if="isAppTypeCU" class="mt-8">
                    {{ $t("APPLICATION.FORM.HELP_DESCRIPTION") }}
                    <a :href="landingPageUrl" target="_blank" class="font-weight-bold">
                       {{ $t("APPLICATION.FORM.HELP_FREQUENT_QUESTIONS") }}
                    </a>
                </p>
            </form>
        </div>
    </b-card>
</template>
<script>
import { ApplicationModel } from "@/modules/applications/models/application-model";
import { FormSchema } from '@/shared/form/form-schema';
import { createFormValidation } from '@/shared/utils/create-form-validation';
import { mapGetters } from "vuex";

const { fields } = ApplicationModel;

const formSchema = new FormSchema([
    fields.application_type_id,
]);
export default {
    name: "ApplicationDetailsForm",
    props: {
        appId: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            rules: formSchema.rules(),
            model: null,
            fields,
            fv: null,
            CUKey: 'CERTIFICAT_URBANISM',
            isAppTypeCU: false,
            landingPageUrl: `${window.VUE_APP_LANDING_PAGE_URL}/intrebari-frecvente`,
        };
    },
    watch: {
        model: {
            deep: true,
            handler(val) {
                const selectedAppType = this.fields.application_type_id.options.find((field)=> field.value === val.application_type_id);
                this.isAppTypeCU = selectedAppType?.key === this.CUKey;
                this.$emit('change', val);
            },
        },
    },
    computed: {
        ...mapGetters({
            record: 'applications/form/record',
            currentUser: 'auth/currentUser',
        }),
        autoSelectFirstOption() {
            return !!this.appId ? false : true;
        }
    },
    created() {
        this.model = formSchema.initialValues({});

        if (this.appId) {
            this.model.application_type_id = this.appId;
        }
    },
    async mounted() {
        this.fv = createFormValidation('st_application_details_form', this.rules);
    },
}
</script>
